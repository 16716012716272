<template>
  <div class="ms-point-histories-container">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol md="12" sm="12">
            <h1>{{ $t('MS_POINT_HISTORIES_LIST_PAGE_TITLE') }}</h1>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <SearchMSPointHistoryHeader :onSearch="onSearch" :statuses="statuses" :actions="actions" />

        <MSPointHistoryListTable :limit="limit" :activePage="activePage" :items="items" :total="total" />
        <StakingPagination
          :limit="limit"
          :total="total"
          :activePage="activePage"
          @changePage="onChangePage"
          @changePageSize="onChangePageSize"
        />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import MSPointHistoryListTable from '@/components/MSPointHistory/MSPointHistoryListTable';
import SearchMSPointHistoryHeader from '@/components/MSPointHistory/SearchMSPointHistoryHeader';
import { endpoints, env, Constants } from '@/constants';
import dateTimeHelper from '@/utils/datetime-helper';
import sessionStorageHelper from '@/utils/sessionStorage-helper';
import StakingPagination from '@/components/StakingPagination';
import moment from 'moment';
import responseHelper from '@/utils/response-helper';
import { mapState, mapActions, mapGetters } from 'vuex';
import stringHelper from '@/utils/string-helper';

  export default {
    name: 'MSPointHistoryList',
    components: {
      MSPointHistoryListTable,
      SearchMSPointHistoryHeader,
      StakingPagination
    },
    data() {
      return {
        items: [],
        filterData: {},
        statuses: [],
        actions: [],
        total: 0,
        limit: env.app.pageSize,
        activePage: 1,
        dateFormat: Constants.dateFormat,
      };
    },
    computed: {
      offset() {
        return (this.activePage - 1) * this.limit;
      },
      pages() {
        return Math.ceil(this.total / this.limit);
      },
    },
    async mounted() {
      await Promise.all([this.getMSPointHistories(), this.getStatuses(), this.getActions()]);
      this.isLoading = false;
    },
    methods: {
      async getStatuses() {
        try {
          const result = await this.$http.get(endpoints.getMSPointStatuses);
          this.statuses = stringHelper.translateTextForDropdownList(result.data, 'MS_POINT_HISTORY_STATUS');
        } catch (err) {
          this.$notify({
            group: 'notifications',
            type: 'error',
            title: this.$t('MS_POINT_HISTORY_LIST_PAGE_TITLE_NOTIFY_ERROR'),
            text: responseHelper.getErrorMessage(err),
          });
        }
      },
      async getActions() {
        try {
          const result = await this.$http.get(endpoints.getMSPointActions);
          this.actions = stringHelper.translateTextForDropdownList(result.data, 'MS_POINT_HISTORY_ACTION');
        } catch (err) {
          this.$notify({
            group: 'notifications',
            type: 'error',
            title: this.$t('MS_POINT_HISTORY_LIST_PAGE_TITLE_NOTIFY_ERROR'),
            text: responseHelper.getErrorMessage(err),
          });
        }
      },
      async getMSPointHistories(data) {
        data = data || {};
        this.filterData = {
          ...this.filterData,
          ...data,
        };
        const filterData = this.filterData;

        try {
          const params = {
            limit: this.limit,
            offset: this.offset,
            from_date: filterData.dateRange.startDate,
            to_date: dateTimeHelper.adjustToDate(filterData.dateRange.endDate),
            email: filterData.email,
            status: filterData.status,
            action: filterData.action
          };
          const result = await this.$http.get(endpoints.getMSPointHistories, { params });

          this.items = result.data.items || [];
          this.total = result.data.total;
        } catch (err) {
          this.$notify({
            group: 'notifications',
            type: 'error',
            title: this.$t('MS_POINT_HISTORY_LIST_PAGE_TITLE_NOTIFY_ERROR'),
            text: responseHelper.getErrorMessage(err),
          });
        }
      },
      async onChangePage(page) {
        this.activePage = page;

        this.getMSPointHistories();
      },
      async onChangePageSize(value) {
        this.limit = value;
        this.activePage = 1;

        this.getMSPointHistories();
      },
      async onSearch(searchData) {
        const data = searchData;
        this.activePage = 1;
        this.getMSPointHistories(data);
      },
    }
  }
</script>
<style lang="scss">
.ms-point-histories-container {
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
    }
  }
}
</style>
