<template>
  <div>
    <CCard>
      <CDataTable :items="items" :fields="fields" fixed hover striped bordered>
        <template ##="{item, index}">
          <td>{{ index + 1 + (activePage - 1) * limit }}</td>
        </template>
        <template #created_at="{item}">
          <td style="text-align: center">{{ item.created_at | moment(dateFormat) }}</td>
        </template>
        <template #email="{item}">
          <td style="text-algin: left">{{ item.member.email }}</td>
        </template>
        <template #status="{item}">
          <td>
            <StakingBadge type="ms-point-history-status" :text="item.status" />
          </td>
        </template>
        <template #action="{item}">
          <td>
            <StakingBadge type="ms-point-history-action" :text="item.action" />
          </td>
        </template>
        <template #amount="{item}">
          <td style="text-align: center">{{ item.amount }}</td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>
<script>
import { Constants, env } from '@/constants';
import StakingBadge from '@/components/StakingBadge';
export default {
  name: 'MSPointHistoryListTable',
  components: {
    StakingBadge,
  },
  data(){
    return {
      dateFormat: Constants.dateFormat,
      fields: [
        '#',
        {
          key: 'created_at',
          label: this.$t('MS_POINT_HISTORY_LIST_TABLE_COMPONENT_DATE_COL'),
          _style: 'min-width: 120px;text-align: center',
        },
        {
          key: 'email',
          label: this.$t('MS_POINT_HISTORY_LIST_TABLE_COMPONENT_EMAIL_COL'),
          _style: 'min-width: 160px; text-align: left;',
        },
        {
          key: 'status',
          label: this.$t('MS_POINT_HISTORY_LIST_TABLE_COMPONENT_STATUS_COL'),
        },
        {
          key: 'action',
          label: this.$t('MS_POINT_HISTORY_LIST_TABLE_COMPONENT_ACTION_COL'),
        },
        {
          key: 'amount',
          label: this.$t('MS_POINT_HISTORY_LIST_TABLE_COMPONENT_AMOUNT_COL'),
          _style: 'text-align: center;',
        },
      ]
    }
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  method: {

  }
}
</script>
<style lang="scss">
  .table {
    margin-bottom: 0;
  }
</style>
